import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from 'vue'; // const storeInfo = ref()

export default {
  __name: 'modal-detail',

  setup(__props, {
    expose: __expose
  }) {
    const titletext = ref();
    const detail = ref();
    const centerDialogVisible = ref(false);

    const showModal = data => {
      console.log(data);
      detail.value = data;
      titletext.value = '查看详情';
      centerDialogVisible.value = true;
    };

    __expose({
      showModal
    }); // 关闭


    const close = formEl => {
      if (!formEl) return;
      formEl.resetFields();
    };

    return (_ctx, _cache) => {
      const _component_el_descriptions_item = _resolveComponent("el-descriptions-item");

      const _component_el_descriptions = _resolveComponent("el-descriptions");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_dialog, {
        modelValue: centerDialogVisible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => centerDialogVisible.value = $event),
        title: titletext.value,
        "align-center": "",
        onClose: _cache[1] || (_cache[1] = $event => close(_ctx.formRef))
      }, {
        default: _withCtx(() => [_createVNode(_component_el_descriptions, {
          class: "margin-top",
          column: 3,
          size: _ctx.size,
          border: ""
        }, {
          default: _withCtx(() => [_createVNode(_component_el_descriptions_item, {
            label: "ID"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(detail.value.ID), 1)]),
            _: 1
          }), _createVNode(_component_el_descriptions_item, {
            label: "管理ID"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(detail.value.pid), 1)]),
            _: 1
          }), _createVNode(_component_el_descriptions_item, {
            label: "管理用户名"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(detail.value.name), 1)]),
            _: 1
          }), _createVNode(_component_el_descriptions_item, {
            label: "标题"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(detail.value.title), 1)]),
            _: 1
          })]),
          _: 1
        }, 8, ["size"]), _createVNode(_component_el_descriptions, {
          class: "margin-top",
          column: 1,
          size: _ctx.size,
          border: ""
        }, {
          default: _withCtx(() => [_createVNode(_component_el_descriptions_item, {
            label: "URL"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(detail.value.url), 1)]),
            _: 1
          }), _createVNode(_component_el_descriptions_item, {
            label: "IP"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(detail.value.Ip), 1)]),
            _: 1
          }), _createVNode(_component_el_descriptions_item, {
            label: "userAgent"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(detail.value.userAgent), 1)]),
            _: 1
          }), _createVNode(_component_el_descriptions_item, {
            label: "创建时间"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(detail.value.create_time), 1)]),
            _: 1
          })]),
          _: 1
        }, 8, ["size"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }

};